@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .bg-primary {
    @apply bg-[#ffff];
  }
  .bg-secondary {
    @apply bg-[#fbfbff];
  }
  .bg-accent {
    @apply bg-[#3434df];
  }
  .text-primary {
    @apply text-[#ffff];
  }
  .text-secondary {
    @apply text-[#fbfbff];
  }
  .text-accent {
    @apply text-[#3434df];
  }
  .gray-description {
    @apply text-base text-gray-600;
  }
}
