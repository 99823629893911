.container{
	/* background: url("../../../../assets/images/booking/paymentGradientbg.jpg"),no-repeat; */
	/* background-size: cover;
	background-position: top right; */
	/* background-color: rgb(238, 237, 237); */
    border-radius: 12px;
    padding: 12px ;

}
.bookingContainer{
    background: url(../../assets/images/booking/background.png) no-repeat;
    background-size: cover;
    background-position: top right;
    

}

/* glassmorphism  */

.glass-morphism{
background: rgba(255, 248, 248, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(8.6px);
-webkit-backdrop-filter: blur(8.6px);
border: 1px solid rgba(184, 181, 181, 0.349);
}
.child-glass-morphism{
background: rgba(201, 199, 199, 0.09);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10.6px);
-webkit-backdrop-filter: blur(10.6px);
border: 1px solid rgba(201, 199, 199, 0.36);
}

.child-glass-morphism-light{
background: rgba(201, 199, 199, 0.09);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(2.6px);
-webkit-backdrop-filter: blur(2.6px);
border: 1px solid rgba(201, 199, 199, 0.36);
}



.Toastify__toast {
    height: 90px;

}


.multiform-container{
    width: min(80em, 100%);
}

/* .next-btn, .prev-btn{
    background-color: rgb(52, 52, 223);
} */

/* import fonts. */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.poppins-font{
    font-family: "Poppins", sans-serif;
}
.pageTittle{
    border-bottom: 3px solid; /* Specify the width of the border */
    border-image-slice: 1;
    border-image-source: linear-gradient(to right,#E67E22,#C8102E);


}
