.sender-form-container input, .sender-form-container select, .sender-form-container textarea{
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0.5em;
}

@media only screen and (max-width: 586px) {
    .sender-form-container{
        flex-direction: column;
        margin: 0;
    }

    .sender-form-container input, .sender-form-container select{
        margin: 1em 0;
    }
    
    .sender-form-container textarea{
        margin-top: 1em;
    }
}