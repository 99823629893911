@media print {
  /* Ensure body takes up the full page size */
  /* body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  /* .print-content {
    width: 2in;
    height: 1in; 
    margin: 0;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    page-break-inside: avoid;
    position: relative;
  } */

  .page-break {
    page-break-before: always;
  }

  @page {
    size: 4in 2in; /* Page size in inches */
    margin: 0; /* Set margin to 0 for no margins */
  }

}