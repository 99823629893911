.payment-container{
    width: min(20em, 100%);
    margin: 0 auto;
    min-height: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-input{
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0.5em;
    border-radius: 0.4em;
    margin-top: 0.8em;
}