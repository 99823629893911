.active{
    background-color: #747474;
    padding: 0;
    padding: 12px 0;
    padding-left: 12px;
    border-radius: 12px;

}


/* ..... custom scroll bar..... */

.scrollbar{
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

