.Toastify__toast-container {
    height: 200px;

}
/* 
.Toastify__progress-bar--warning {
    background-color: greenyellow;
  
}
.Toastify__toast--warning {
   
    
   
} */

/* .Toastify__toast-theme--colored.Toastify__toast--warning {
} */